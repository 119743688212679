import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import herobg1 from "../../resources/img/Main/hero-bg-1.jpg";
import herobg2 from "../../resources/img/Main/hero-bg-2.jpg";

import "./IntroSection.scss";

const hero = [herobg1, herobg2, herobg1, herobg2];

function IntroSection() {
  const { t } = useTranslation();

  return (
    <section className="intro">
      <div className="intro__header">
        {hero.map((it, i) => (
          <img key={i} src={it} alt="" />
        ))}

        <div className="intro__overlay">
          <Container>
            <div dangerouslySetInnerHTML={{ __html: t("intro-title") }}></div>

            <div dangerouslySetInnerHTML={{ __html: t("intro-text") }}></div>

            <Link to="/about" className="button button-fourth">
              {t("О нас")}
            </Link>
          </Container>
        </div>
      </div>
    </section>
  );
}

export default IntroSection;
