import AboutSection from "../Sections/AboutSection";
import AdvantageSection from "../Sections/AdvantageSection";
import IntroSection from "../Sections/IntoSection";
import ServiceSection from "../Sections/ServiceSection";

export default function MainPage() {
  return (
    <>
      <IntroSection />
      <AdvantageSection />
      <AboutSection />
      <ServiceSection />
    </>
  );
}
