import { useTranslation } from "react-i18next";
import MyForm from "../Form/MyForm";

import { Col, Container } from "react-bootstrap";

import "./ContactsPage.scss";

function ContactsPage() {
  const { t } = useTranslation();

  return (
    <section className="contacts">
      <div className="contacts__header">
        <div className="contacts__overlay">
          <Container className="text-center">
            <h1>{t("contacts")}</h1>
            <span>{t("contacts-callback")}</span>
          </Container>
        </div>
      </div>

      <div className="contacts__contact">
        <div className="contacts__contact-overlay">
          <Container>
            <h5 className="text-center text-white">{t("Контакты")}</h5>
            <h4 className="text-center text-white fw-bold mb-5 fs-2">
              {t("contacts-sub")}
            </h4>
            <Col md={10} className="m-auto">
              <MyForm
                rightClass={"contacts-form-info"}
                class={"form-info-inner"}
              />
            </Col>
          </Container>
        </div>
      </div>

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d752.7635915856415!2d71.19619477985798!3d41.00218323813734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38a4d5047dffdd39%3A0x7e9a26d6f8501733!2sAKSARTEKS%20INDUSTRY!5e0!3m2!1sen!2s!4v1717917263444!5m2!1sen!2s"
        width="100%"
        height="500"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </section>
  );
}

export default ContactsPage;
