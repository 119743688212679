import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import Collages1 from "../../resources/img/ProductsPage/collapse/Collages1.jpg";
import Collages2 from "../../resources/img/ProductsPage/collapse/Collages2.jpg";

import f_0_0 from "../../resources/img/ProductsPage/f-0-0.jpg";
import f_0_1 from "../../resources/img/ProductsPage/f-0-1.jpg";
import f_1_0 from "../../resources/img/ProductsPage/f-1-0.jpg";
import f_1_1 from "../../resources/img/ProductsPage/f-1-1.jpg";
import f_10_0 from "../../resources/img/ProductsPage/f-10-0.jpg";
import f_10_1 from "../../resources/img/ProductsPage/f-10-1.jpg";
import f_11_0 from "../../resources/img/ProductsPage/f-11-0.jpg";
import f_11_1 from "../../resources/img/ProductsPage/f-11-1.jpg";
import f_12_0 from "../../resources/img/ProductsPage/f-12-0.jpg";
import f_12_1 from "../../resources/img/ProductsPage/f-12-1.jpg";
import f_13_0 from "../../resources/img/ProductsPage/f-13-0.jpg";
import f_13_1 from "../../resources/img/ProductsPage/f-13-1.jpg";
import f_14_0 from "../../resources/img/ProductsPage/f-14-0.jpg";
import f_14_1 from "../../resources/img/ProductsPage/f-14-1.jpg";
import f_15_0 from "../../resources/img/ProductsPage/f-15-0.jpg";
import f_15_1 from "../../resources/img/ProductsPage/f-15-1.jpg";
import f_2_0 from "../../resources/img/ProductsPage/f-2-0.jpg";
import f_2_1 from "../../resources/img/ProductsPage/f-2-1.jpg";
import f_3_0 from "../../resources/img/ProductsPage/f-3-0.jpg";
import f_3_1 from "../../resources/img/ProductsPage/f-3-1.jpg";
import f_4_0 from "../../resources/img/ProductsPage/f-4-0.jpg";
import f_4_1 from "../../resources/img/ProductsPage/f-4-1.jpg";
import f_5_0 from "../../resources/img/ProductsPage/f-5-0.jpg";
import f_5_1 from "../../resources/img/ProductsPage/f-5-1.jpg";
import f_6_0 from "../../resources/img/ProductsPage/f-6-0.jpg";
import f_6_1 from "../../resources/img/ProductsPage/f-6-1.jpg";
import f_7_0 from "../../resources/img/ProductsPage/f-7-0.jpg";
import f_7_1 from "../../resources/img/ProductsPage/f-7-1.jpg";
import f_8_0 from "../../resources/img/ProductsPage/f-8-0.jpg";
import f_8_1 from "../../resources/img/ProductsPage/f-8-1.jpg";
import f_9_0 from "../../resources/img/ProductsPage/f-9-0.jpg";
import f_9_1 from "../../resources/img/ProductsPage/f-9-1.jpg";
import p_0_0 from "../../resources/img/ProductsPage/p-0-0.jpg";
import p_0_1 from "../../resources/img/ProductsPage/p-0-1.jpg";
import p_1_0 from "../../resources/img/ProductsPage/p-1-0.jpg";
import p_1_1 from "../../resources/img/ProductsPage/p-1-1.jpg";
import p_2_0 from "../../resources/img/ProductsPage/p-2-0.jpg";
import p_2_1 from "../../resources/img/ProductsPage/p-2-1.jpg";
import p_3_0 from "../../resources/img/ProductsPage/p-3-0.jpg";
import p_3_1 from "../../resources/img/ProductsPage/p-3-1.jpg";
import p_4_0 from "../../resources/img/ProductsPage/p-4-0.jpg";
import p_4_1 from "../../resources/img/ProductsPage/p-4-1.jpg";
import p_5_0 from "../../resources/img/ProductsPage/p-5-0.jpg";
import p_5_1 from "../../resources/img/ProductsPage/p-5-1.jpg";

import Fancybox from "../ImageViewer/Fancybox";
import "./ProductsPage.scss";

const hero = [Collages1, Collages2, Collages1, Collages2];

export const futbolka = [
  {
    color: "products.green",
    title: "products.shirt",
    imgs: [f_0_0, f_0_1],
    text: "product.t-shirt-desc",
  },
  {
    color: "products.light-green",
    title: "products.shirt",
    imgs: [f_1_0, f_1_1],
    text: "product.t-shirt-desc",
  },
  {
    color: "products.yellow",
    title: "products.shirt",
    imgs: [f_2_0, f_2_1],
    text: "product.t-shirt-desc",
  },
  {
    color: "products.red",
    title: "products.shirt",
    imgs: [f_3_0, f_3_1],
    text: "product.t-shirt-desc",
  },
  {
    color: "products.beige",
    title: "products.shirt",
    imgs: [f_4_0, f_4_1],
    text: "product.t-shirt-desc",
  },
  {
    color: "products.orange",
    title: "products.shirt",
    imgs: [f_5_0, f_5_1],
    text: "product.t-shirt-desc",
  },
  {
    color: "products.peach",
    title: "products.shirt",
    imgs: [f_6_0, f_6_1],
    text: "product.t-shirt-desc",
  },
  {
    color: "products.blue",
    title: "products.shirt",
    imgs: [f_7_0, f_7_1],
    text: "product.t-shirt-desc",
  },
  {
    color: "products.grey",
    title: "products.shirt",
    imgs: [f_8_0, f_8_1],
    text: "product.t-shirt-desc",
  },
  {
    color: "products.dark",
    title: "products.shirt",
    imgs: [f_9_0, f_9_1],
    text: "product.t-shirt-desc",
  },
  {
    color: "products.white",
    title: "products.shirt",
    imgs: [f_10_0, f_10_1],
    text: "product.t-shirt-desc",
  },
  {
    color: "products.khaki",
    title: "products.shirt",
    imgs: [f_11_0, f_11_1],
    text: "product.t-shirt-desc",
  },
  {
    color: "products.light-gray",
    title: "products.shirt",
    imgs: [f_12_0, f_12_1],
    text: "product.t-shirt-desc",
  },
  {
    color: "products.lilac",
    title: "products.shirt",
    imgs: [f_13_0, f_13_1],
    text: "product.t-shirt-desc",
  },
  {
    color: "products.blue",
    title: "products.shirt",
    imgs: [f_14_0, f_14_1],
    text: "product.t-shirt-desc",
  },
  {
    color: "products.black",
    title: "products.shirt",
    imgs: [f_15_0, f_15_1],
    text: "product.t-shirt-desc",
  },
];

export const hudi = [];

export const polo = [
  {
    color: "products.green",
    title: "products.shirt",
    imgs: [p_0_0, p_0_1],
    text: "product.polo-desc",
  },
  {
    color: "products.blue",
    title: "products.shirt",
    imgs: [p_1_0, p_1_1],
    text: "product.polo-desc",
  },
  {
    color: "products.red",
    title: "products.shirt",
    imgs: [p_2_0, p_2_1],
    text: "product.polo-desc",
  },
  {
    color: "products.blue",
    title: "products.shirt",
    imgs: [p_3_0, p_3_1],
    text: "product.polo-desc",
  },
  {
    color: "products.white",
    title: "products.shirt",
    imgs: [p_4_0, p_4_1],
    text: "product.polo-desc",
  },
  {
    color: "products.black",
    title: "products.shirt",
    imgs: [p_5_0, p_5_1],
    text: "product.polo-desc",
  },
];

export default function ProductsPage() {
  const [t] = useTranslation();

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <section className="products">
      <div className="products__header">
        {hero.map((it, i) => (
          <img key={i} src={it} alt="" />
        ))}

        <div className="products__overlay">
          <Container className="text-center">
            <h1>{t("productpage")}</h1>

            <p>AKSARTEKS</p>
          </Container>
        </div>
      </div>

      <Container>
        <div className="products__wrapper">
          <h3>{t("productcollect")}</h3>

          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  scrollButtons="auto"
                  centered
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label={t("productChild")}
                    disabled={futbolka.length === 0}
                    value="1"
                  />
                  <Tab
                    label={t("productMen")}
                    disabled={hudi.length === 0}
                    value="2"
                  />
                  <Tab
                    label={t("productWomen")}
                    disabled={polo.length === 0}
                    value="3"
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Fancybox options={{ infinite: false }}>
                  <Row>
                    {futbolka.map(({ title, color, imgs: [it, it2] }, i) => (
                      <Col className="product" md={3} key={i}>
                        <div className="product__wrapper">
                          <a
                            className="product-hoverable-top"
                            data-fancybox="gallery"
                            data-src={it}
                          >
                            <img src={it} width="400" alt="?" />
                          </a>
                          <a
                            className="product-hoverable"
                            data-fancybox="gallery"
                            data-src={it2}
                          >
                            <img src={it2} width="400" alt="?" />
                          </a>
                        </div>

                        <p className="product__title">{t(title)}</p>
                        <p className="product__color">
                          {t("products.color")}: {t(color)}
                        </p>

                        <Link
                          className="button button--dark button-fourth"
                          to={`/products/t-shirt/${i}`}
                        >
                          {t("products.more")}
                        </Link>
                      </Col>
                    ))}
                  </Row>
                </Fancybox>
              </TabPanel>

              <TabPanel value="2">
                <Row>
                  {hudi.map(([it, it2], i) => (
                    <Col
                      style={{
                        position: "relative",
                      }}
                      md={4}
                      key={i}
                    >
                      <a
                        className="product-hoverable-top"
                        data-fancybox="gallery"
                        data-src={it}
                      >
                        <img src={it} width="400" alt="?" />
                      </a>
                      <a
                        className="product-hoverable"
                        data-fancybox="gallery"
                        data-src={it2}
                      >
                        <img src={it2} width="400" alt="?" />
                      </a>
                    </Col>
                  ))}
                </Row>
              </TabPanel>

              <TabPanel value="3">
                <Row>
                  {polo.map(({ title, color, imgs: [it, it2] }, i) => (
                    <Col className="product" md={4} key={i}>
                      <div className="product__wrapper">
                        <a
                          className="product-hoverable-top"
                          data-fancybox="gallery"
                          data-src={it}
                        >
                          <img src={it} width="400" alt="?" />
                        </a>
                        <a
                          className="product-hoverable"
                          data-fancybox="gallery"
                          data-src={it2}
                        >
                          <img src={it2} width="400" alt="?" />
                        </a>
                      </div>

                      <p className="product__title">{t(title)}</p>
                      <p className="product__color">
                        {t("products.color")}: {t(color)}
                      </p>

                      <Link
                        className="button button--dark button-fourth"
                        to={`/products/polo/${i}`}
                      >
                        {t("products.more")}
                      </Link>
                    </Col>
                  ))}
                </Row>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </Container>
    </section>
  );
}
