import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import "./product.scss";
import { futbolka, polo } from "./ProductsPage";

function Product() {
  const { t } = useTranslation();

  const { type, id } = useParams();

  const products = type === "polo" ? polo : futbolka;
  const currProduct = products[id];
  const [emblaReference] = useEmblaCarousel(
    {
      loop: true,
    },
    [
      Autoplay({
        delay: 3000,
        stopOnInteraction: false,
      }),
    ]
  );

  return (
    <div className="pr-page">
      <div className="pr-top">
        <div className="pr-top__left">
          <img src={currProduct.imgs[0]} alt="" />
          <img src={currProduct.imgs[1]} alt="" />
        </div>

        <div className="pr-top__right">
          <h2 className="pr-top__title">{t(currProduct.title)}</h2>

          <div className="pr-top__list">
            {[
              { title: t("product.size"), header: t("product.L-XXXL") },
              { title: t("product.composition"), header: t("product.cotton") },
              {
                title: t("product.density"),
                header:
                  type === "t-shirt"
                    ? t("product.density160")
                    : t("product.density200"),
              },
              {
                title: t("product.fabric"),
                header:
                  type === "t-shirt"
                    ? t("product.single-jersey")
                    : t("product.pique"),
              },
              { title: t("product.floor"), header: t("product.male") },
              { title: t("product.printing"), header: t("product.yes") },
              {
                title: t("product.production"),
                header: t("product.uzbekistan"),
              },
              {
                title: t("product.color"),
              },
            ].map((it, i) => (
              <div className="pr-top__item" key={i}>
                <p className="pr-top__item-title">{it.title}</p>
                <p className="pr-top__item-text">
                  {it.header ??
                    t(
                      type === "t-shirt" ? currProduct.color : currProduct.color
                    )}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <p className="pr-text">{t(currProduct.text)}</p>

      <div className="pr-carousel" ref={emblaReference}>
        <div className="pr-carousel-content">
          {products.map(({ imgs: [img], title, color }, i) => (
            <Link
              to={"/products/" + type + "/" + i}
              className="pr-carousel__item"
              key={i}
            >
              <img className="pr-carousel__img" src={img} alt="" />

              <div>
                <p className="pr-carousel__title">{t(title)}</p>

                <p className="product__color">
                  {t("products.color")}: {t(color)}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Product;
