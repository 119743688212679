import { TextField } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Form } from "react-bootstrap";

import InputMask from "react-input-mask";

import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";

import { contacts } from "../../consts";
import "./MyForm.scss";

function MyForm(props) {
  const submit = async (event) => {
    event.preventDefault();

    let name = document.querySelector("#modal-name").value;
    let phone = document.querySelector("#modal-phone").value;
    let email = document.querySelector("#modal-email").value;
    let description = document.querySelector("#modal-description").value;

    try {
      fetch("/api/", {
        method: "POST",
        body: JSON.stringify({
          name: name,
          phone: phone,
          email: email,
          message: description,
        }),
        headers: {
          "Content-Type": "application/json",
        },
        next: {
          revalidate: 0,
        },
      }).then(() => {
        event.target.reset();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [t] = useTranslation();

  const style = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#ce9e51",
      },
      "& fieldset": {
        borderColor: "#ffffff",
      },
      "&:hover fieldset": {
        borderColor: "#d1b17e",
      },
    },
  };

  return (
    <Row className="my-form">
      <Col md={4} className={props.rightClass}>
        <div className={props.class}>
          <LocationOnIcon />
          <div>
            <h5>{t("form-address")}</h5>

            <h6>{t("form-production")}</h6>
            <span>{t("form-production-name")}</span>

            <h6>{t("form-production-vyz")}</h6>
            <span>{t("form-production-vyz-name")}</span>

            <h6>{t("form-office")}</h6>
            <span>{t("form-office-name")}</span>
          </div>
        </div>
        <div className={props.class}>
          <PhoneIphoneIcon />
          <div>
            <h5>{t("form-phone")}</h5>

            <span>{contacts.tel}</span>
          </div>
        </div>
        <div className={props.class}>
          <EmailIcon />
          <div>
            <h5>{t("form-email")}</h5>

            <span>{contacts.email}</span>
          </div>
        </div>
      </Col>

      <Col md={8} className="my-form-right">
        <Form onSubmit={submit}>
          <Row className="mx-2 mb-5">
            <Col xs={12} className="mb-4">
              <TextField
                fullWidth
                label={t("form-name")}
                name="name"
                sx={style}
                id="modal-name"
                required
              />
            </Col>
            <Col xs={6} className="mb-4">
              <InputMask
                mask="+\9\9\8 \(99) 999-99-99"
                maskChar={null}
                name="number"
                fullWidth
                required
              >
                {(inputProps) => (
                  <TextField
                    id="modal-phone"
                    sx={style}
                    label={t("form-number")}
                    {...inputProps}
                  />
                )}
              </InputMask>
            </Col>
            <Col xs={6} className="mb-4">
              <TextField
                id="modal-email"
                label="Email"
                name="email"
                sx={style}
                fullWidth
                type="email"
                required
              />
            </Col>
            <Col xs={12} className="mb-4">
              <TextField
                id="modal-description"
                fullWidth
                sx={style}
                label={t("form-sms")}
                name="description"
                rows={3}
              />
            </Col>
            <div>
              <button
                type="submit"
                className="button button--dark button-fourth"
              >
                {t("form-send")}
              </button>
            </div>
          </Row>
        </Form>
      </Col>
    </Row>
  );
}

export default MyForm;
