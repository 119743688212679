import CheckIcon from "@mui/icons-material/Check";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { HashLink } from "react-router-hash-link";
import MyForm from "../Form/MyForm";
import SvgMap from "../SvgMap/SvgMap";

import herobg1 from "../../resources/img/About/hero-bg-1.jpg";
import herobg2 from "../../resources/img/About/hero-bg-2.jpg";

import img2 from "../../resources/img/About/zig-2.jpg";
import img3 from "../../resources/img/About/zig-3.jpg";
import img4 from "../../resources/img/About/zig-4.jpg";
import img5 from "../../resources/img/About/zig-5.jpg";

import PaymentIcon from "@mui/icons-material/Payment";

import {
  Discount,
  EmojiTransportation,
  LocalMall,
  LocalShipping,
  Place,
} from "@mui/icons-material";
import "./About.scss";

export default function AboutPage() {
  const [t] = useTranslation();

  return (
    <section className="about">
      <div className="about__header">
        <img src={herobg1} alt="" />
        <img src={herobg2} alt="" />
        <img src={herobg1} alt="" />
        <img src={herobg2} alt="" />

        <div className="about__overlay">
          <Container>
            <div dangerouslySetInnerHTML={{ __html: t("about-title") }}></div>

            <div dangerouslySetInnerHTML={{ __html: t("intro-text") }}></div>

            <HashLink to="/about#story" className="button button-fourth">
              {t("more")}
            </HashLink>
          </Container>
        </div>
      </div>

      <div className="about__story">
        <Row className="g-0">
          <Col md={6} className="about__story-left" />

          <Col id="story" md={6} className="about__story-right">
            <div className="about__story-right-box">
              <h2 className="about__story-sup">{t("about-story")}</h2>
              <h2 className="about__story-sub">{t("about-story-sub")}</h2>
              <p>{t("about-parag")}</p>
              <p className="parag3">{t("about-parag3")}</p>

              <ul>
                <li>
                  <CheckIcon />
                  {t("about-li1")}
                </li>
                <li>
                  <CheckIcon />
                  {t("about-li2")}
                </li>
                <li>
                  <CheckIcon />
                  {t("about-li3")}
                </li>
                <li>
                  <CheckIcon />
                  {t("about-li4")}
                </li>
                <li>
                  <CheckIcon />
                  {t("about-li5")}
                </li>
              </ul>

              <p className="mb-5">{t("about-parag2")}</p>

              <HashLink
                to="/about#callback"
                className="button button--dark button-fourth"
              >
                {t("callback")}
              </HashLink>
            </div>
          </Col>
        </Row>
      </div>

      <section className="advantage py">
        <Container>
          <Row className="advantage__mission">
            <h2>{t("advantages")}</h2>
            <Col data-aos="flip-down" md={4} className="mb-3">
              <div className="advantage__mission-box">
                <PaymentIcon />

                <h3>{t("advan1")}</h3>

                <p>{t("adven1-text")}</p>
              </div>
            </Col>

            <Col data-aos="flip-down" md={4} className="mb-3">
              <div className="advantage__mission-box">
                <Place />

                <h3>{t("advan2")}</h3>

                <p>{t("adven2-text")}</p>
              </div>
            </Col>

            <Col data-aos="flip-down" md={4} className="mb-3">
              <div className="advantage__mission-box">
                <Discount />

                <h3>{t("advan3")}</h3>

                <p>{t("adven3-text")}</p>
              </div>
            </Col>

            <Col data-aos="flip-down" md={4} className="mb-3">
              <div className="advantage__mission-box">
                <LocalMall />

                <h3>{t("advan4")}</h3>

                <p>{t("adven4-text")}</p>
              </div>
            </Col>

            <Col data-aos="flip-down" md={4} className="mb-3">
              <div className="advantage__mission-box">
                <EmojiTransportation />

                <h3>{t("advan5")}</h3>

                <p>{t("adven5-text")}</p>
              </div>
            </Col>

            <Col data-aos="flip-down" md={4} className="mb-3">
              <div className="advantage__mission-box">
                <LocalShipping />

                <h3>{t("advan6")}</h3>

                <p>{t("adven6-text")}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="about__facilities">
        <div className="g-0 about__facilities-img">
          {[
            // {
            //   img: img1,
            //   title: t("aboutadv-title"),
            //   sub: t("aboutadv-sub"),
            //   text: [t("aboutadv-text"), t("aboutadv-text2")],
            // },
            {
              img: img2,
              title: t("aboutexp-title"),
              text: [t("aboutexp-text"), t("aboutexp-text2")],
            },
            {
              id: "3",
              img: img3,
              title: t("about3-title"),
              text: [t("about3-text")],
            },
            {
              id: "4",
              img: img4,
              title: t("about4-title"),
              text: [t("about4-text")],
            },
            {
              id: "5",
              img: img5,
              title: t("about5-title"),
              text: [t("about5-text"), t("about5-text2")],
            },
          ].map(({ img, text: [text1, text2], title, sub, id }, i) => (
            <div key={i} id={id}>
              <img
                data-aos={i % 2 === 0 ? "zoom-in-left" : "zoom-in-right"}
                src={img}
                alt=""
              />

              <div
                data-aos={i % 2 !== 0 ? "zoom-in-left" : "zoom-in-right"}
                md={6}
                className="about__facilities-text"
              >
                <div>
                  <h5>{title}</h5>
                  {!!sub && <h4>{sub}</h4>}

                  <p>{text1}</p>
                  <p className="overflow-hidden h-0">{text2}</p>

                  {!!text2 && (
                    <button
                      className="button button--dark button-fourth"
                      onClick={(evt) => {
                        evt.currentTarget.previousElementSibling.classList.toggle(
                          "h-0"
                        );
                      }}
                    >
                      {t("more")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        <Container>
          <SvgMap />
        </Container>

        <div className="about__contact" id="callback">
          <div className="about__contact-overlay">
            <Container>
              <h5 className="text-center text-white">{t("Контакты")}</h5>
              <h4 className="text-center text-white fw-bold mb-5 fs-2">
                {t("contact-sub")}
              </h4>
              <MyForm class={"my-form-info"} />
            </Container>
          </div>
        </div>
      </div>
    </section>
  );
}
