import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";

import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import Fancybox from "../ImageViewer/Fancybox";
import { futbolka, hudi, polo } from "../Pages/ProductsPage.jsx";

import { t } from "i18next";
import "./Tab.scss";

function Tabs() {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="tabs__component container">
      <Link to="/products">{t("product")}</Link>
      <h4>{t("productsub")}</h4>

      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              scrollButtons="auto"
              centered
              onChange={handleChange}
              aria-label="scrollable auto  tabs example"
            >
              <Tab
                label={t("productChild")}
                disabled={futbolka.length === 0}
                value="1"
              />
              <Tab
                label={t("productMen")}
                disabled={hudi.length === 0}
                value="2"
              />
              <Tab
                label={t("productWomen")}
                disabled={polo.length === 0}
                value="3"
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Fancybox options={{ infinite: false }}>
              <Row>
                {futbolka
                  .slice(0, 3)
                  .map(({ title, imgs: [it, it2], color }, i) => (
                    <Col className="product dark" md={4} key={i}>
                      <div className="product__wrapper dark">
                        <a
                          className="product-hoverable-top"
                          data-fancybox="gallery"
                          data-src={it}
                        >
                          <img src={it} width="400" alt="?" />
                        </a>
                        <a
                          className="product-hoverable"
                          data-fancybox="gallery"
                          data-src={it2}
                        >
                          <img src={it2} width="400" alt="?" />
                        </a>
                      </div>

                      <div>
                        <p className="product__title">{t(title)}</p>

                        <p className="product__color">
                          {t("products.color")}: {t(color)}
                        </p>
                      </div>

                      <Link
                        className="button button--dark button-fourth"
                        to={`/products/t-shirt/${i}`}
                      >
                        {t("products.more")}
                      </Link>
                    </Col>
                  ))}
              </Row>
            </Fancybox>
          </TabPanel>

          <TabPanel value="2">
            <Fancybox options={{ infinite: false }}>
              <Row>
                {hudi.slice(0, 3).map(([it, it2], i) => (
                  <Col md={4} key={i}>
                    <a
                      className="product-hoverable-top"
                      data-fancybox="gallery"
                      data-src={it}
                    >
                      <img src={it} width="400" alt="?" />
                    </a>
                    <a
                      className="product-hoverable"
                      data-fancybox="gallery"
                      data-src={it2}
                    >
                      <img src={it2} width="400" alt="?" />
                    </a>
                  </Col>
                ))}
              </Row>
            </Fancybox>
          </TabPanel>

          <TabPanel value="3">
            <Fancybox options={{ infinite: false }}>
              <Row>
                {polo
                  .slice(0, 3)
                  .map(({ title, imgs: [it, it2], color }, i) => (
                    <Col className="product dark" md={4} key={i}>
                      <div className="product__wrapper dark">
                        <a
                          className="product-hoverable-top"
                          data-fancybox="gallery"
                          data-src={it}
                        >
                          <img src={it} width="400" alt="?" />
                        </a>
                        <a
                          className="product-hoverable"
                          data-fancybox="gallery"
                          data-src={it2}
                        >
                          <img src={it2} width="400" alt="?" />
                        </a>
                      </div>

                      <div>
                        <p className="product__title">{t(title)}</p>

                        <p className="product__color">
                          {t("products.color")}: {t(color)}
                        </p>
                      </div>

                      <Link
                        className="button button--dark button-fourth"
                        to={`/products/polo/${i}`}
                      >
                        {t("products.more")}
                      </Link>
                    </Col>
                  ))}
              </Row>
            </Fancybox>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}

export default Tabs;
