import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../../resources/img/logo-black.png";
import "./Footer.scss";

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer__overlay">
        <Container>
          <Row>
            <Col md={4}>
              <Link to={"/"}>
                <img src={logo} alt="AKSARTEKS" />
              </Link>

              <p>{t("footer-title")}</p>
            </Col>
            <Col md={4}>
              <h3>{t("company")}</h3>

              <div className="footer__link">
                <Link to="/about">{t("О нас")}</Link>
                <Link to="/products">{t("Продукция")}</Link>
                {/* <Link to="/productions">{t("Производство")}</Link> */}
                <Link to="contacts">{t("Контакты")}</Link>
              </div>
            </Col>
            <Col md={4}>
              <h3>{t("contact-us")}</h3>

              <div className="footer__link" style={{ marginBottom: "20px" }}>
                <a href="tel:+998500080097">+99850-008-00-97</a>
              </div>

              <h3>{t("contact-us-production")}</h3>

              <div className="footer__link">
                <a href="tel:+998902792153">+99890-279-21-53</a>
                <a href="tel:+998913515297">+99891-351-52-97</a>
                <a href="tel:+998911834814">+99891-183-48-14</a>
              </div>

              <h3 className="mt-5">{t("address")}</h3>

              <div className="footer__link">
                <p>{t("address-name")}</p>
              </div>

              <p className="socials">
                <a
                  href={"https://instagram.com/aksarteks"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    viewBox="0 0 32 32"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={1.5}
                  >
                    <g>
                      <rect width="26.9" height="26.9" x="2.6" y="2.6" rx="8" />
                      <circle cx="16" cy="16" r="6" />
                      <circle cx="23.3" cy="8.3" r="1.7" />
                    </g>
                  </svg>
                </a>

                <a
                  href={"https://www.facebook.com/aksartex?mibextid=LQQJ4d"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg viewBox="0 0 32 32" fill="currentColor">
                    <path d="M22 5h-3.4c-3.2 0-5.2 2.1-5.2 5.4V13H10v4.5h3.3V27H18v-9.6h3.8V13H18v-2.1c0-1 .2-1.5 1.6-1.5h2.3V5z" />
                  </svg>
                </a>

                <a
                  href={"https://t.me/aksartex"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    viewBox="0 0 48 48"
                    stroke="currentColor"
                    fill="none"
                    strokeWidth={2.5}
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M40.8 8.5c1.2 0 2 1 1.6 2.8l-5.6 26.3c-.4 2-1.5 2.4-3 1.5l-13.4-9.8a.4.4 0 0 1 0-.7l15.4-13.9c.7-.6-.2-.9-1.1-.3L15.4 26.5a.5.5 0 0 1-.4 0L6.8 24c-1.8-.5-1.8-1.8.4-2.7L40 8.7a2.2 2.2 0 0 1 .8-.2Z"
                    />
                  </svg>
                </a>
              </p>
            </Col>

            <Col className="footer__bottom">
              <span>AKSARTEKS {new Date().getFullYear()}</span>

              <a href="https://wtma.uz/">Website developer wtma.uz</a>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
